import React, { useState } from "react";
import BookingTable from "./BookingTable"
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { showLoading, hideLoading } from '../../../../actions/ui';

function ReservationContainer() {
    const currentDate = new Date();
    const dispatch = useDispatch();
    const currentMonth = currentDate.toISOString().slice(0, 7); // Định dạng YYYY-MM
    const [selectedMonth, setSelectedMonth] = useState(currentMonth);
    const token = useSelector(state => state.token);
    const CONST_DATA = {
        "byArea": {
            "Ho Chi Minh": {
                "booking": 0,
                "roomAmount": 0
            },
            "Ha Noi": {
                "booking": 0,
                "roomAmount": 0
            },
            "Cambodia": {
                "booking": 0,
                "roomAmount": 0
            },
            "Da Nang": {
                "booking": 0,
                "roomAmount": 0
            },
            "Hai Phong": {
                "booking": 0,
                "roomAmount": 0
            },
            "AZTEST": {
                "booking": 0,
                "roomAmount": 0
            }
        },
        "byBranch": {
            "AZLTT": {
                "booking": 0,
                "roomAmount": 0,
                "deviceType": {
                    "tablet": 0,
                    "pc": 0,
                    "phone": 0,
                    "app": 0
                },
                "language": {
                    "ja": 0,
                    "en": 0,
                    "vi": 0,
                    "ko": 0
                }
            },
            "AZTVL1": {
                "booking": 0,
                "roomAmount": 0,
                "deviceType": {
                    "tablet": 0,
                    "pc": 0,
                    "phone": 0,
                    "app": 0
                },
                "language": {
                    "ja": 0,
                    "en": 0,
                    "vi": 0,
                    "ko": 0
                }
            },
            "AZTVL2": {
                "booking": 0,
                "roomAmount": 0,
                "deviceType": {
                    "tablet": 0,
                    "pc": 0,
                    "phone": 0,
                    "app": 0
                },
                "language": {
                    "ja": 0,
                    "en": 0,
                    "vi": 0,
                    "ko": 0
                }
            },
            "AZKM3": {
                "booking": 0,
                "roomAmount": 0,
                "deviceType": {
                    "tablet": 0,
                    "pc": 0,
                    "phone": 0,
                    "app": 0
                },
                "language": {
                    "ja": 0,
                    "en": 0,
                    "vi": 0,
                    "ko": 0
                }
            },
            "AZKM2": {
                "booking": 0,
                "roomAmount": 0,
                "deviceType": {
                    "tablet": 0,
                    "pc": 0,
                    "phone": 0,
                    "app": 0
                },
                "language": {
                    "ja": 0,
                    "en": 0,
                    "vi": 0,
                    "ko": 0
                }
            },
            "AZLL": {
                "booking": 0,
                "roomAmount": 0,
                "deviceType": {
                    "tablet": 0,
                    "pc": 0,
                    "phone": 0,
                    "app": 0
                },
                "language": {
                    "ja": 0,
                    "en": 0,
                    "vi": 0,
                    "ko": 0
                }
            },
            "AZPP": {
                "booking": 0,
                "roomAmount": 0,
                "deviceType": {
                    "tablet": 0,
                    "pc": 0,
                    "phone": 0,
                    "app": 0
                },
                "language": {
                    "ja": 0,
                    "en": 0,
                    "vi": 0,
                    "ko": 0
                }
            },
            "AZDN": {
                "booking": 0,
                "roomAmount": 0,
                "deviceType": {
                    "tablet": 0,
                    "pc": 0,
                    "phone": 0,
                    "app": 0
                },
                "language": {
                    "ja": 0,
                    "en": 0,
                    "vi": 0,
                    "ko": 0
                }
            },
            "AZHBT1": {
                "booking": 0,
                "roomAmount": 0,
                "deviceType": {
                    "tablet": 0,
                    "pc": 0,
                    "phone": 0,
                    "app": 0
                },
                "language": {
                    "ja": 0,
                    "en": 0,
                    "vi": 0,
                    "ko": 0
                }
            },
            "AZHP": {
                "booking": 0,
                "roomAmount": 0,
                "deviceType": {
                    "tablet": 0,
                    "pc": 0,
                    "phone": 0,
                    "app": 0
                },
                "language": {
                    "ja": 0,
                    "en": 0,
                    "vi": 0,
                    "ko": 0
                }
            },
            "AZTEST": {
                "booking": 0,
                "roomAmount": 0,
                "deviceType": {
                    "tablet": 0,
                    "pc": 0,
                    "phone": 0,
                    "app": 0
                },
                "language": {
                    "ja": 0,
                    "en": 0,
                    "vi": 0,
                    "ko": 0
                }
            }
        }
    }
    const [data, setData]= useState(CONST_DATA);

    const handleChange = (event) => {

        const callAPI = async (month) => {
            dispatch(showLoading())
            const result = await axios.get(`https://database.azumayareport.com/booking/report_reservation/${month}`);
            console.log("result", result)
            if(result.data) {
                if(Object.keys(result.data.byArea).length > 0)  setData(result.data);
                else setData(CONST_DATA)
            }
            dispatch(hideLoading())

        }
        setSelectedMonth(event.target.value);
        callAPI(event.target.value);
      };

      
    useState(()=>{

        const callAPI = async () => {
            dispatch(showLoading())
            const result = await axios.get(`https://database.azumayareport.com/booking/report_reservation/${selectedMonth}`);
            if(result.data) {
                if(Object.keys(result.data.byArea).length > 0)  setData(result.data);
                else setData(CONST_DATA);
            }
            dispatch(hideLoading())
        }

        if(token) {
            callAPI();
        }
     
    },[token, selectedMonth]);

    return (
        <div className="reservation-container">
            <h1>Reservation Report</h1>
            <input
                type="month"
                id="month-input"
                value={selectedMonth}
                style={{fontSize:'1.6rem'}}
                onChange={handleChange}
            />

            <BookingTable data ={data}/>
        </div>
    );
}

export default ReservationContainer;